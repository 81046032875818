/* eslint-disable */
export default {
    data: () => ({

    }),
    methods: {
        clearUser () {
            const vm = this
            vm.$store.dispatch('users/clearUser').then(function () {
                vm.$router.replace('login')
            })
        },
        loadPlans () {
            const vm = this
            vm.$store.dispatch('subscriptions/loadPlans').then(() => {
                // console.log('se cargaron los subcri')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadStripe () {
            const vm = this
            vm.$store.dispatch('subscriptions/loadStripe').then(() => {
                // console.log('se cargo stripe')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadPayments () {
            const vm = this
            vm.$store.dispatch('subscriptions/loadPayments').then(() => {
                // console.log('se cargo stripe')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadBanking () {
            const vm = this
            vm.$store.dispatch('withdraw/loadBanking').then(() => {
                // console.log('se cargo la informacion bancaria')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadWithdraw () {
            const vm = this
            vm.$store.dispatch('withdraw/loadWithdraw').then(() => {
                // console.log('se cargaron los withdraw')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadCollaborators () {
            const vm = this
            vm.$store.dispatch('collaborators/loadCollaborators', 'collaborators').then(() => {
                // console.log('se cargaron los administradores')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadProfile () {
            const vm = this
            vm.$store.dispatch('users/loadProfile').then(() => {
                // console.log('se cargo el perfil')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadUploads () {
            const vm = this
            vm.$store.dispatch('uploads/loadUploads').then(() => {
                // console.log('se cargo las subidas')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadMedia () {
            const vm = this
            vm.$store.dispatch('uploads/loadMedia').then(() => {
                // console.log('se cargo media')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadDownloads () {
            const vm = this
            vm.$store.dispatch('downloads/loadDownloads').then(() => {
                // console.log('se cargo las descargas')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadNotifications () {
            const vm = this
            vm.$store.dispatch('notifications/loadNotifications').then(() => {
                // console.log('se cargo las notificaciones')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadNoRead () {
            const vm = this
            vm.$store.dispatch('notifications/loadNoRead').then(() => {
                // console.log('se cargo las notificaciones no leidas')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        // ADMIIINN FUNCTIONS
        loadUploadsPending () {
            const vm = this
            vm.$store.dispatch('admin/loadUploadsPending').then(() => {
                // console.log('se cargo las subidas pendientes')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
        loadWithdrawPending () {
            const vm = this
            vm.$store.dispatch('admin/loadWithdraw').then(() => {
                // console.log('se cargo los withdraw pendientes')
            }, function (err) {
                // console.log(err, 'Permision denied error')
            })
        },
    },

    created () {
        // let vm = this;
        // // console.log('CREATED---------------------------------------------------------------')
        // firebase.auth().onAuthStateChanged(function(currentUser) {
        //     try{
        //         if (currentUser) {
        //             vm.loadCategories();
        //             vm.loadLocations();
        //             vm.loadProviders();
        //             // vm.loadExpenses();
        //             vm.loadBills();
        //             vm.loadSubscriptions();
        //             vm.loadProducts();
        //         }else{
        //             vm.clearUser();
        //         }
        //     }catch(err){
        //         //errores
        //         vm.clearUser();
        // //        console.log(err)
        //     }
        // });
    },
}
//

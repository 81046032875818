<template lang="pug">
    v-container(fluid="")
      // Watch
      v-dialog( :fullscreen='isMobile' persistent :value='watchDialog'  max-width='600px'   transition="dialog-bottom-transition")
        div(style ='position:relative; height: 100%;')
          watch-videos(:model ='video' v-if="watchDialog" @close="watchDialog=false")
          div(style='position:absolute; top:0; width:100%;')
            v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='updateItemDialog=false')
              v-icon(size="30") mdi-close-box
      // Update
      v-dialog( persistent :value='updateVideoDialog' max-width='800px' transition="dialog-bottom-transition")
        div(style ='position:relative; height: 100%;')
          update-dialog(:model ='video' v-if="updateVideoDialog" :update='true' @close="updateVideoDialog=false")
          div(style='position:absolute; top:0; width:100%;')
            v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='updateItemDialog=false')
              v-icon(size="30") mdi-close-box
      // Upload
      v-dialog( persistent :value='uploadDialog' max-width='800px' transition="dialog-bottom-transition")
        upload-videos(:model ='video' v-if="uploadDialog" @close="uploadDialog=false")
      //- content
      template(v-if="uploads")
        v-layout().video-card__wrapper.mx-auto
          v-toolbar(color="secondary")
            v-layout(style='display: flex; flex-direction: row;')
              .toolbar-header.mr-auto
                p.white--text The following are your previously uploaded content.
              .toolbar-header.ml-auto
                v-btn( :fab='isMobile' color="secondary" small @click='uploadDialog = true')
                  v-icon(:left="!isMobile" color="#fff") mdi-upload
                  span(v-if="!isMobile") Upload content
          v-row
            v-card.video-card.my-10.my-videos(v-for="(upload, i) in uploads" style="margin: 0 auto")
                .video-card__img-wrapper
                  span(v-if="upload.type.includes('video')")
                    v-img(:src='upload.thumbnail' height="180px" width="100%" style="clip-path: inset(1rem);")
                  span(v-else)
                    v-img(:src='upload.url' height="180px" width="100%" style="clip-path: inset(1rem);")
                v-layout
                    v-flex.text-center
                        h2 {{upload.title}}
                v-layout
                    v-flex.d-flex.align-center.justify-center
                        img(src="@/assets/images/location-icon.png")
                        span.ml-2 {{upload.location.locality}}, {{upload.location.state}}
                v-layout
                  v-flex.d-flex.align-center.justify-center
                    span.my-1.video-card__location.primary--text {{upload.type}}
                v-layout(v-if="upload.created")
                    v-flex.text-center
                      p Submitted Date:
                      span {{upload.created | moment}}
                v-layout
                    v-layout.d-flex.align-center.justify-center(col="6")
                        img(src="@/assets/images/calendar-icon.png")
                        p.ml-2 {{upload.date | moment}}
                    v-layout.d-flex.align-center.justify-center(col="6")
                        img(src="@/assets/images/time-icon.png")
                        p.ml-2 {{upload.time}}
                v-layout
                    v-layout(col)
                        v-flex.d-flex.align-center.justify-center
                            p.mb-0.my-videos__price {{upload.price | moneyFormat}}
                    v-layout(col)
                        v-flex.d-flex.align-center.justify-center
                            v-btn.text-uppercase.mx-auto.my-videos__play(color="primary" @click="watch(upload)") See
                    v-layout(col)
                        v-flex.d-flex.align-center.justify-center
                            v-btn(v-if="upload.status === 'rejected'" icon color="secondary" @click="update(upload)")
                              v-icon mdi-pencil
                            h3.red--text {{upload.status}}
      template(v-else)
        v-row
          v-col(cols="12")
            v-card-title.justify-center.font-weight-light
              | You haven't media content yet
            v-card-text.body-1.text-center.font-weight-light.grey--text
              | The content you will upload appear here.
          v-col(cols="12").text-center
            v-btn.mr-0(color="secondary" medium @click='uploadDialog = true')
              | Upload content
</template>

<script>
  import loaders from '@/plugins/mixins/loaders'
  import moment from 'moment'
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {

    components: {
      UpdateDialog: () => import('@/components/custom/dialogs/Videos/UpdateDialog.vue'),
      WatchVideos: () => import('@/components/custom/dialogs/Videos/WatchDialog.vue'),
      UploadVideos: () => import('@/components/custom/dialogs/Videos/UploadsDialog.vue'),
    },

    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
    },

    mixins: [loaders],

    data () {
      return {
        video: '',
        updateVideoDialog: false,
        watchDialog: false,
        uploadDialog: false,
        snackbar: {
          show: false,
          color: 'red',
          message: '',
        },
      }
    },

    computed: {
      isMobile () {
        return window.innerWidth < 1024
      },
      uploads () {
        return this.$store.state.uploads.uploads_list
      },
    },

    created () {
      const vm = this
      vm.loadUploads()
    },

    methods: {
      watch (upload) {
        const vm = this
        vm.video = upload
        vm.watchDialog = true
      },
      update (upload) {
        // console.log(upload)
        const vm = this
        vm.video = upload
        vm.updateVideoDialog = true
      },
    },
  }
</script>

<style lang="scss">
.video-card{
  &__wrapper{
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column ;
  }
}
.my-videos {
  &__play{
    font-size: 18px !important;
  }
  &__price{
    font-weight: bold;
    font-size: 22px;
    color: var(--secondary-color);
  }
  &__show-more{
    color: var(--secondary-color) !important;
    font-size: 15px;
  }
}
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
</style>
